import { gql } from '@apollo/client';
import {
  Checkbox,
  FormSection,
  Select,
  TextField,
} from '@tackle-io/platform-ui';
import InfoItem from 'components/InfoItem';
import { Field, FieldProps, Form, Formik } from 'formik';
import {
  useUpdateVendorBillingMutation,
  useUpdateVendorMutation,
  useVendorConfigQuery,
  VendorConfigQuery,
} from 'generated/graphql';
import { xor } from 'lodash';
import { Cloud } from 'mdi-material-ui';
import { VendorEnvironmentEnum } from 'pages/Vendor/VendorEnvironment.hooks';
import React from 'react';
import { useParams } from 'react-router-dom';
import { MARKETPLACE_NAMES_MAP, MARKETPLACES } from 'utils/constant';
import { Box, FormControlLabel, Grid } from 'vendor/material';
import {
  array as yupArray,
  number as yupNumber,
  object as yupObject,
  string as yupString,
} from 'yup';

import ConfigButton from './ConfigButton';
import ConfigCard from './ConfigCard';
import useStyles from './VendorConfig.styles';

type VendorBillingValues = {
  subscriptionIds: string;
  subscriptionStartTimeOverride: string;
  subscriptionEndTimeOverride: string;
  vendorIds: string;
  // offerIds: Array<string>;
  gmvPrecommit: number | '';
  prospectPrecommit: number | '';
  cosellMarketplacesOverride: Array<string>;
};

type VendorValues = {
  name: string;
};

export const VENDOR_CONFIG_QUERY = gql`
  query VendorConfig($id: ID!) {
    vendor(id: $id) {
      id
      name
      feature_flags {
        is_ami_early_access_program_enabled
        is_co_sell_enabled
        is_co_sell_enabled_for_microsoft
        is_prospect_enabled
        is_marketplace_enabled
      }
      billing {
        id
        gmv_precommit
        prospect_precommit
        cosell_marketplaces_override
        subscription_ids
        subscription_start_time_override
        subscription_end_time_override
        vendor_ids
        # offer_ids
      }
      vendor_type
      configuration {
        id
        gcp {
          partner_id
        }
        azure {
          app_id
          app_secret
          exp_date
          tenant_id
        }
        redhat {
          api_key
        }
      }
    }
  }
`;

export const UPDATE_VENDOR_MUTATION = gql`
  mutation UpdateVendor($updates: VendorInput!) {
    updateVendor(updates: $updates) {
      id
      name
      feature_flags {
        is_ami_early_access_program_enabled
        is_co_sell_enabled
        is_co_sell_enabled_for_microsoft
        is_prospect_enabled
        is_marketplace_enabled
      }
      vendor_type
    }
  }
`;

export const UPDATE_ENTITLEMENT_TO_CO_SELL_VENDOR_MUTATION = gql`
  mutation UpdateEntitlementToCoSellVendor($updates: CoSellVendorInput!) {
    updateEntitlementToCoSellVendor(updates: $updates) {
      id
      ace_entitled_status
      mspc_entitled_status
      ace_entitled_updated_at
      mspc_entitled_updated_at
    }
  }
`;

export const UPDATE_VENDOR_BILLING_MUTATION = gql`
  mutation UpdateVendorBilling($updates: VendorBillingConfigurationInput!) {
    updateVendorBillingConfiguration(updates: $updates) {
      id
      subscription_ids
      subscription_start_time_override
      subscription_end_time_override
      vendor_ids
      gmv_precommit
      prospect_precommit
      cosell_marketplaces_override
    }
  }
`;

const ALLOWED_VENDOR_TYPES = [
  { type: VendorEnvironmentEnum.PROD, label: 'Production' },
  { type: VendorEnvironmentEnum.CUSTOMER_TEST, label: 'Customer test' },
  { type: VendorEnvironmentEnum.INTERNAL_TEST, label: 'Internal test' },
  { type: VendorEnvironmentEnum.DEMO, label: 'Demo' },
  { type: VendorEnvironmentEnum.DEV, label: 'Development' },
  { type: VendorEnvironmentEnum.SANDBOX, label: 'Test environment' },
];

const Subtitle = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();

  return <div className={classes.subtitle}>{children}</div>;
};

const ConfigSection = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  const classes = useStyles();
  return (
    <div className={classes.configSection}>
      <Subtitle>{title}</Subtitle>
      {children}
    </div>
  );
};

const vendorBillingToValues = (
  data: VendorConfigQuery | undefined,
): VendorBillingValues => {
  const billingData = data?.vendor?.billing;

  return {
    subscriptionIds: billingData?.subscription_ids?.join(',') || '',
    subscriptionStartTimeOverride: billingData?.subscription_start_time_override
      ? new Date(billingData?.subscription_start_time_override)
          ?.toISOString()
          .split('T')[0]
      : '',
    subscriptionEndTimeOverride: billingData?.subscription_end_time_override
      ? new Date(billingData?.subscription_end_time_override)
          ?.toISOString()
          .split('T')[0]
      : '',
    vendorIds: billingData?.vendor_ids?.join(',') || '',
    // offerIds: billingData?.offer_ids?.join(',') || '',
    gmvPrecommit: billingData?.gmv_precommit ?? 0,
    prospectPrecommit: billingData?.prospect_precommit ?? 0,
    cosellMarketplacesOverride: billingData?.cosell_marketplaces_override || [],
  };
};

const valuesToVendorBilling = (values: VendorBillingValues) => {
  return {
    subscription_ids: values.subscriptionIds
      ? values.subscriptionIds.split(',').map((v) => v.trim())
      : [],
    subscription_start_time_override:
      values.subscriptionStartTimeOverride || null,
    subscription_end_time_override: values.subscriptionEndTimeOverride || null,
    vendor_ids: values.vendorIds
      ? values.vendorIds.split(',').map((v) => v.trim())
      : [],
    // offer_ids: values.offerIds
    //   ? values.offerIds.split(',').map((v) => v.trim())
    //   : [],
    gmv_precommit: values.gmvPrecommit || 0,
    prospect_precommit: values.prospectPrecommit || 0,
    cosell_marketplaces_override: values.cosellMarketplacesOverride || [],
  };
};

const vendorNameSchema = yupObject().shape({
  name: yupString(),
});

const vendorBillingSchema = yupObject().shape({
  subscriptionIds: yupString(),
  subscriptionStartTimeOverride: yupString(),
  subscriptionEndTimeOverride: yupString(),
  vendorIds: yupString(),
  // offerIds: yupArray().of(yupString()),
  gmvPrecommit: yupNumber().integer(),
  prospectPrecommit: yupNumber().integer(),
  cosellMarketplacesOverride: yupArray().of(yupString()),
});

const VendorConfig: React.FC = () => {
  const classes = useStyles();
  const { vendorid } = useParams<{ vendorid: string }>();

  const { data: vendorData, loading: isFetching } = useVendorConfigQuery({
    variables: {
      id: vendorid,
    },
  });

  const [mutateAsync, { loading: isLoading }] = useUpdateVendorMutation();

  const [mutateVendorBilling, { loading: isVendorBillingLoading }] =
    useUpdateVendorBillingMutation();

  const loading = isFetching || isLoading;

  const isAmiEarlyAccessEnabled = Boolean(
    vendorData?.vendor?.feature_flags?.is_ami_early_access_program_enabled,
  );
  const isCoSellEnabled = Boolean(
    vendorData?.vendor?.feature_flags?.is_co_sell_enabled,
  );
  const isCoSellEnabledForMicrosoft = Boolean(
    vendorData?.vendor?.feature_flags?.is_co_sell_enabled_for_microsoft,
  );
  const isProspectEnabled = Boolean(
    vendorData?.vendor?.feature_flags?.is_prospect_enabled,
  );
  const isMarketplaceEnabled = Boolean(
    vendorData?.vendor?.feature_flags?.is_marketplace_enabled,
  );
  const vendorType = vendorData?.vendor?.vendor_type;
  const gcpPartnerId =
    vendorData?.vendor?.configuration?.gcp?.partner_id || null;

  return (
    <div>
      <ConfigCard
        Icon={<Cloud />}
        title="Cloud Provider Configuration"
        RenderRight={<ConfigButton />}
        loading={loading}
      >
        <ConfigSection title="General">
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControlLabel
                label="Co-Sell for AWS"
                disabled={loading}
                control={
                  <Box mx={1} className={classes.checkboxWrapper}>
                    <Checkbox
                      onChange={async (): Promise<void> => {
                        try {
                          await mutateAsync({
                            variables: {
                              updates: {
                                id: vendorid,
                                feature_flags: {
                                  is_co_sell_enabled: !isCoSellEnabled,
                                },
                              },
                            },
                          });
                        } catch (error) {
                          console.error(error);
                        }
                      }}
                      disabled={loading}
                      checked={isCoSellEnabled}
                      disableRipple={false}
                      aria-label="Enable Co-Sell for AWS"
                    />
                  </Box>
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControlLabel
                label="Co-Sell for Microsoft"
                disabled={loading}
                control={
                  <Box mx={1} className={classes.checkboxWrapper}>
                    <Checkbox
                      onChange={async (): Promise<void> => {
                        try {
                          await mutateAsync({
                            variables: {
                              updates: {
                                id: vendorid,
                                feature_flags: {
                                  is_co_sell_enabled_for_microsoft:
                                    !isCoSellEnabledForMicrosoft,
                                },
                              },
                            },
                          });
                        } catch (error) {
                          console.error(error);
                        }
                      }}
                      disabled={loading}
                      checked={isCoSellEnabledForMicrosoft}
                      disableRipple={false}
                      aria-label="Enable Co-Sell for Microsoft"
                    />
                  </Box>
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControlLabel
                label="Prospect"
                disabled={loading}
                control={
                  <Box mx={1} className={classes.checkboxWrapper}>
                    <Checkbox
                      onChange={async (): Promise<void> => {
                        try {
                          await mutateAsync({
                            variables: {
                              updates: {
                                id: vendorid,
                                feature_flags: {
                                  is_prospect_enabled: !isProspectEnabled,
                                },
                              },
                            },
                          });
                        } catch (error) {
                          console.error(error);
                        }
                      }}
                      disabled={loading}
                      checked={isProspectEnabled}
                      disableRipple={false}
                      aria-label="Enable Prospect"
                    />
                  </Box>
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControlLabel
                label="Marketplace"
                disabled={loading}
                control={
                  <Box mx={1} className={classes.checkboxWrapper}>
                    <Checkbox
                      onChange={async (): Promise<void> => {
                        try {
                          await mutateAsync({
                            variables: {
                              updates: {
                                id: vendorid,
                                feature_flags: {
                                  is_marketplace_enabled: !isMarketplaceEnabled,
                                },
                              },
                            },
                          });
                        } catch (error) {
                          console.error(error);
                        }
                      }}
                      checked={isMarketplaceEnabled}
                      disableRipple={false}
                      aria-label="Enable Marketplace"
                    />
                  </Box>
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box mt={2}>
                <Formik<VendorValues>
                  enableReinitialize
                  initialValues={{
                    name: vendorData?.vendor?.name || '',
                  }}
                  onSubmit={async ({ name }: VendorValues): Promise<void> => {
                    await mutateAsync({
                      variables: {
                        updates: {
                          id: vendorid,
                          name: name,
                        },
                      },
                    });
                  }}
                  validationSchema={vendorNameSchema}
                >
                  {({ handleSubmit }) => (
                    <Form>
                      <Field name="name">
                        {({
                          field,
                          meta,
                        }: FieldProps<VendorValues['name']>) => (
                          <TextField
                            {...field}
                            label="Vendor name"
                            disabled={isFetching || isLoading}
                            onBlur={(e) => {
                              field.onBlur(e);
                              handleSubmit();
                            }}
                            error={meta.error}
                          />
                        )}
                      </Field>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mt={2}>
                <Select
                  id="vendor-type-options"
                  label="Vendor Type"
                  disabled={
                    loading || vendorType === VendorEnvironmentEnum.SANDBOX
                  }
                  onChange={async (e): Promise<void> => {
                    try {
                      await mutateAsync({
                        variables: {
                          updates: {
                            id: vendorid,
                            vendor_type: e.target.value,
                          },
                        },
                      });
                    } catch (error) {
                      console.error(error);
                    }
                  }}
                  // @ts-ignore I am pretty sure the platform-ui type is just incorrect
                  value={vendorType || ''}
                >
                  <option value="" disabled>
                    Select vendor type
                  </option>
                  {ALLOWED_VENDOR_TYPES.map((item) => {
                    return (
                      <option
                        value={item.type}
                        key={item.type}
                        disabled={item.type === VendorEnvironmentEnum.SANDBOX}
                      >
                        {item.label}
                      </option>
                    );
                  })}
                </Select>
              </Box>
            </Grid>
          </Grid>
        </ConfigSection>
        <ConfigSection title="AWS">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControlLabel
                label="AMI EAP"
                disabled={loading}
                control={
                  <Box mx={1} className={classes.checkboxWrapper}>
                    <Checkbox
                      onChange={async (): Promise<void> => {
                        try {
                          await mutateAsync({
                            variables: {
                              updates: {
                                id: vendorid,
                                feature_flags: {
                                  is_ami_early_access_program_enabled:
                                    !isAmiEarlyAccessEnabled,
                                },
                              },
                            },
                          });
                        } catch (error) {
                          console.error(error);
                        }
                      }}
                      disabled={loading}
                      checked={isAmiEarlyAccessEnabled}
                      disableRipple={false}
                      aria-label="Enable AMI early access program"
                    />
                  </Box>
                }
              />
            </Grid>
          </Grid>
        </ConfigSection>
        <ConfigSection title="Azure">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <InfoItem sensitive title="TENANT ID">
                {vendorData?.vendor?.configuration?.azure?.tenant_id}
              </InfoItem>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <InfoItem sensitive title="APP SECRET">
                {vendorData?.vendor?.configuration?.azure?.app_secret}
              </InfoItem>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <InfoItem sensitive title="APP ID">
                {vendorData?.vendor?.configuration?.azure?.app_id}
              </InfoItem>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <InfoItem title="EXPIRATION DATE">
                {vendorData?.vendor?.configuration?.azure?.exp_date}
              </InfoItem>
            </Grid>
          </Grid>
        </ConfigSection>
        <ConfigSection title="GCP">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <InfoItem title="PARTNER ID">{gcpPartnerId}</InfoItem>
            </Grid>
          </Grid>
        </ConfigSection>
        <ConfigSection title="Red Hat">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <InfoItem sensitive title="API KEY VALUE">
                {vendorData?.vendor?.configuration?.redhat?.api_key}
              </InfoItem>
            </Grid>
          </Grid>
        </ConfigSection>
        <ConfigSection title="Billing Configuration">
          <Formik
            enableReinitialize
            initialValues={vendorBillingToValues(vendorData)}
            onSubmit={(formValues) =>
              mutateVendorBilling({
                variables: {
                  updates: {
                    id: vendorid,
                    ...valuesToVendorBilling(formValues),
                  },
                },
              })
            }
            validationSchema={vendorBillingSchema}
          >
            {({ handleSubmit }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Field name="gmvPrecommit">
                      {({
                        field,
                        meta,
                      }: FieldProps<VendorBillingValues['gmvPrecommit']>) => (
                        <TextField
                          {...field}
                          label="Marketplace Revenue Forecast"
                          type="number"
                          disabled={isFetching || isVendorBillingLoading}
                          onBlur={(e) => {
                            field.onBlur(e);
                            handleSubmit();
                          }}
                          error={meta.error}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field name="prospectPrecommit">
                      {({
                        field,
                        meta,
                      }: FieldProps<
                        VendorBillingValues['prospectPrecommit']
                      >) => (
                        <TextField
                          {...field}
                          label="Prospect Scores"
                          type="number"
                          disabled={isFetching || isVendorBillingLoading}
                          onBlur={(e) => {
                            field.onBlur(e);
                            handleSubmit();
                          }}
                          error={meta.error}
                        />
                      )}
                    </Field>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Field name="offerIds">
                      {({ field, meta }: FieldProps<VendorBillingValues["offerIds"]>) => (
                        <TextField
                          {...field}
                          label="Offer IDs"
                          helperText="Values must be comma separated"
                          multiline
                          rows="4"
                          disabled={isFetching || isVendorBillingLoading}
                          onBlur={() => handleSubmit()}
                          error={meta.error}
                        />
                      )}
                    </Field>
                  </Grid> */}
                  <Grid item xs={12}>
                    <Field name="vendorIds">
                      {({
                        field,
                        meta,
                      }: FieldProps<VendorBillingValues['vendorIds']>) => (
                        <TextField
                          {...field}
                          label="Vendor IDs"
                          helperText="Values must be comma separated"
                          multiline
                          rows="4"
                          disabled={isFetching || isVendorBillingLoading}
                          onBlur={(e) => {
                            field.onBlur(e);
                            handleSubmit();
                          }}
                          error={meta.error}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="subscriptionIds">
                      {({
                        field,
                        meta,
                      }: FieldProps<
                        VendorBillingValues['cosellMarketplacesOverride']
                      >) => (
                        <TextField
                          {...field}
                          label="Subscription IDs"
                          helperText="Values must be comma separated"
                          multiline
                          rows="4"
                          disabled={isFetching || isVendorBillingLoading}
                          onBlur={(e) => {
                            field.onBlur(e);
                            handleSubmit();
                          }}
                          error={meta.error}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="subscriptionStartTimeOverride">
                      {({
                        field,
                        meta,
                        form,
                      }: FieldProps<
                        VendorBillingValues['subscriptionStartTimeOverride']
                      >) => (
                        <TextField
                          {...field}
                          label="Subscription Start Time Override"
                          type="date"
                          disabled={isFetching || isVendorBillingLoading}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            form.setFieldValue(
                              'subscriptionStartTimeOverride',
                              e.target.value,
                            );
                          }}
                          onBlur={(e) => {
                            field.onBlur(e);
                            handleSubmit();
                          }}
                          error={meta.error}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="subscriptionEndTimeOverride">
                      {({
                        field,
                        meta,
                        form,
                      }: FieldProps<
                        VendorBillingValues['subscriptionEndTimeOverride']
                      >) => (
                        <TextField
                          {...field}
                          label="Subscription End Time Override"
                          type="date"
                          disabled={isFetching || isVendorBillingLoading}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            form.setFieldValue(
                              'subscriptionEndTimeOverride',
                              e.target.value,
                            );
                          }}
                          onBlur={(e) => {
                            field.onBlur(e);
                            handleSubmit();
                          }}
                          error={meta.error}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormSection
                      title="Co-Sell Override"
                      children={''}
                      mb={2}
                    />
                    <Box className={classes.coSellOverride}>
                      {MARKETPLACES.map((mp) => (
                        <Field name="cosellMarketplacesOverride" key={mp}>
                          {({
                            field,
                            meta,
                            form,
                          }: FieldProps<
                            VendorBillingValues['cosellMarketplacesOverride']
                          >) => (
                            <FormControlLabel
                              className={classes.checkboxSpacing}
                              label={MARKETPLACE_NAMES_MAP[mp]}
                              control={
                                <Checkbox
                                  {...field}
                                  checked={field.value.includes(mp)}
                                  disabled={
                                    isFetching || isVendorBillingLoading
                                  }
                                  onChange={() => {
                                    form.setFieldValue(
                                      'cosellMarketplacesOverride',
                                      xor(field.value, [mp]),
                                    );
                                    handleSubmit();
                                  }}
                                />
                              }
                            />
                          )}
                        </Field>
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </ConfigSection>
      </ConfigCard>
    </div>
  );
};

export default VendorConfig;
